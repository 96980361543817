<template>
	<div id="usuarios">
		<div class="tabela-usuario">
			<div class="col-12 novo-usuario">
				<v-btn class="primary-button" raised @click="dialog_usuario = true">
					<i class="fas fa-user-plus"></i> Cadastrar Usuário
				</v-btn>
			</div>
			<CustomTable 
				v-if="headers != ''"
				:action="'getUsuarios'"
				:getter="$store.getters.usuarios"
				:headers="headers"
				:search="true"
				:title="'Usuários'"
				:pagination="true"
				:filters="filtros"
				ref="tabela"
			>
				<template v-slot:status="{ item }">
					<span v-if="item.status">
						<img src="@/assets/images/icon-ativo.png">
					</span>
					<span v-else>
						<img src="@/assets/images/icon-inativo.png">
					</span>
				</template>
				<template v-slot:data_adicionado="{ item }">
					<span>
						{{moment(item.data_adicionado).format('LLL')}}
					</span>
				</template>
				<template v-slot:data_atualizado="{ item }">
					<span>
						{{moment(item.data_atualizado).format('LLL')}}
					</span>
				</template>
				<template v-slot:acoes="{ item }">
					<v-btn class="primary-button" raised small @click="editarUsuario(item.usuario_id)">
						<i class="fas fa-cog"></i>
						Editar
					</v-btn>
				</template>
			</CustomTable>
			<v-dialog v-model="dialog_usuario" persistent max-width="600px">
				<v-card>
					<v-card-title>
						<span class="headline" v-if="usuario.usuario_id" > Editar Usuário </span>
						<span class="headline" v-else > Novo Usuário </span>
						<v-btn class="exit-button" small @click="closeUsuario">
                            <v-icon>fas fa-times</v-icon>
                        </v-btn>
						<p>*Campos obrigatórios</p>				
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="form_usuario">
								<div class="row">
									<div class="col-12 div-input">
										<label class="primary-text-field-label">Nome*</label>
										<v-text-field
											class="primary-text-field"
                      :rules="[v => !!v || 'Campo obrigatório']" 
											required= "Required"
											v-model="usuario.nome" 
											label="Nome" 
											hide-details
											solo											
										/>
									</div>
									<div class="col-12 div-input">
										<label class="primary-text-field-label">E-mail*</label>
										<v-text-field
											class="primary-text-field"
											type="email"
                      :rules="[v => !!v || 'Campo obrigatório']" 
											v-model="usuario.email" 
											label="E-mail" 
											hide-details
											solo											
										/>
									</div>
									<div class="col-12 div-input" v-if="!usuario.usuario_id">
										<label class="primary-text-field-label">Senha*</label>
										<v-text-field
											class="primary-text-field"
											type="password"
                      :rules="[v => !!v || 'Campo obrigatório']" 
											v-model="usuario.senha" 
											label="Senha" 
											hide-details
											solo											
										/>
									</div>
									<div class="col-12 div-input col-md-6">
										<label class="primary-text-field-label">Grupo*</label>
										<v-select
											class="primary-select" 
                      :rules="[v => !!v || 'Campo obrigatório']" 
											:items="grupos"
											item-text="nome" 
											item-value="grupo_id"
											v-model="usuario.grupo_id" 
											label="Grupo"
											hide-details
											solo											
										/>
									</div>
									<div class="col-12 div-input col-md-6">
										<label class="primary-text-field-label">Empresa*</label>
										<v-select
											class="primary-select"
											:items="empresas"
											item-text="nome" 
											item-value="empresa_id"
											v-model="usuario.empresa_id" 
											label="Empresa"
											hide-details
											solo											
										/>
									</div>
									<div class="col-12 div-input">
										<label class="primary-text-field-label">Status*</label>
										<v-select
											class="primary-select" 
                      :rules="[v => !!v || 'Campo obrigatório']" 
											:items="['Ativo', 'Inativo']" 
											v-model="usuario.status" 
											label="Status" 
											hide-details
											solo											
										/>
									</div>
								</div>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<div class="wrapper-btns">
							<v-btn class="primary-button" medium @click="enviarUsuario">
								Salvar
							</v-btn>
						</div>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
			<Loader v-if="loading"/>
		</div>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'
	// importa o plugin de request 
	import Request from '@/plugins/request'

	// exporta o componente
	export default {
		// nome do componente
		name: 'Usuarios',
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// variável para mostrar a modal para editar/criar um usuário
			dialog_usuario: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para os grupos
			grupos: [],
			// variável para as empresas
			empresas: [],
			// variável para criar/editar usuário
			usuario: {
				usuario_id: '',
				nome: '',
				email: '',
				senha: '',
				status: '',
				grupo_id: '',
				grupo_nome: '',
				empresa_id: '',
				empresa_nome: '',
				data_adicionado: '',
				data_atualizado: '',
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'usuario_id',
					text: 'ID',
					sortable: true,
				},
				{
					value: 'nome',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'email',
					text: 'E-mail',
					sortable: true,
				},
				{
					value: 'status',
					text: 'Status',
					sortable: true,
				},
				{
					value: 'empresa.nome',
					text: 'Empresa',
					sortable: true,
				},
				{
					value: 'grupo.nome',
					text: 'Grupo',
					sortable: true,
				},
				{
					value: 'data_adicionado',
					text: 'Data adicionado',
					sortable: true,
				},
				{
					value: 'data_atualizado',
					text: 'Última atualização',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 10,
			},
		}),
		// funções deste componente
		methods: {
			// funções de início do componente
			async init(){
				// função para pegar as empresas
				this.getEmpresas()
				// função para pegar os grupos
				this.getGrupos()
			},
			// função para pegar as empresas
			async getEmpresas(){
				// faz a requisição para o back para coletar as empresas
				var resp = await Request({
					// rota da requisição
					route: 'admin/empresa/get_empresa_select'
				})
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel empresas
					this.empresas = resp.data.dados
				}
			},
			// função para pegar os grupos
			async getGrupos(){
				// faz a requisição para o back para coletar os grupos
				var resp = await Request({
					// rota da requisição
					route: 'admin/grupo/get_grupo_select'
				})
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel grupos
					this.grupos = resp.data.dados
				}
			},
			// função para enviar um usuário
			async enviarUsuario(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_usuario.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados do usuário
					let dados = await {
						// coleta o nome do usuário
						nome: await this.usuario.nome,
						// coleta o email do usuário
						email: await this.usuario.email,
						// coleta as empresas do usuário
						empresa: await this.usuario.empresa_id,
						// coleta o grupo do usuário
						grupo: await this.usuario.grupo_id,	
					}
					// caso exista um usuario_id 
					if(this.usuario.usuario_id){
						// coleta o status do usuário
						dados.status = await this.usuario.status == 'Ativo' ? true : false
						// coleta o id do usuário
						dados.usuario_id = await this.usuario.usuario_id
					}
					if(this.usuario.senha != ''){
						dados.senha = await this.usuario.senha
					}
					// faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('postUsuarios', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						this.$swal({
                            icon:'success',
							text: 'Usuário ' + (this.usuario.usuario_id ? 'editado' : 'cadastrado') + ' com sucesso!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true
                        })
						// mostra a mensagem
						this.closeUsuario()
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
				else{
					this.$swal({
						title: 'Atenção',
						icon:'warning',
						text: 'Preencha todos os campos obrigatórios!',
						showCancelButton: false,
						showCloseButton: false,
						showConfirmButton: true,
						confirmButtonText:'Ok'
					})
				}
			},
			// função para coletar um usuário para editar
			async editarUsuario(usuario_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um usuário passando o id 
				var resp = await store.dispatch('getUsuario', usuario_id)
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui os dados do usuário vindos do back à váriável local
					this.usuario.usuario_id = await resp.data.dados[0].usuario_id || ''
					this.usuario.nome = await resp.data.dados[0].nome || ''
					this.usuario.email = await resp.data.dados[0].email || ''
					this.usuario.grupo_id = await resp.data.dados[0].grupo_id || ''
					this.usuario.grupo_nome = await resp.data.dados[0].grupo_nome || ''
					this.usuario.empresa_id = await resp.data.dados[0].empresa_id || '' 
					this.usuario.empresa_nome = await resp.data.dados[0].empresa_nome || ''
					this.usuario.status = await resp.data.dados[0].status ? 'Ativo' : 'Inativo'
					// mostra a modal de creat/edit usuário
					this.dialog_usuario = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit usuário
			closeUsuario(){
				// fecha a modal
				this.dialog_usuario = false
				// limpa os dados locais do usuário
				this.usuario =  {
					usuario_id: '',
					nome: '',
					email: '',
					senha: '',
					repete_senha: '',
					grupo: '',
					empresa: '',
					status: '',
				}
			},
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			this.init()
		},
	}
</script>

<style lang="scss">
	#usuarios{
		display: flex;
		flex-wrap: wrap;
		max-height: 100%;
		padding: 24px;
		font-family: 'Poppins';
		.primary-button{
			background-color: #FE8204;
			color: #fff;
			font-weight: 500;
			padding: 15px 20px;
			svg{
				margin-right: 5px;
			}
		}
		.tabela_{
			.status{
				span{
					display: flex;
					align-items: center;
				}
				img{
					width: 20px;
					margin-left: 10px;
				}
			}
		}
		.tabela-usuario{
    		border-radius: 10px;
			width: 100%;	
			.novo-usuario{
				display: flex;
				justify-content: flex-end;
				padding-bottom: 0;
				button{
					i, svg{
						margin-right: 10px;
					}
					text-transform: uppercase;
				}
			}
		}
	}
	.v-dialog{
		font-family: 'Poppins';
		.v-card{
			background-color: #f9f9f9;
			padding: 15px;
		}
		.v-card__title{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			text-transform: uppercase;
			font-family: 'Poppins' !important;
			span{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				font-weight: 600;
				color: #404040;
				font-family: 'Poppins' !important;
				img, svg{
					margin-right: 15px;
					path{
						fill: #404040;
					}
				}
			}
			.exit-button{
                color: #404040;
                font-weight: 600;
                font-size: 15px;
                box-shadow: none;
                background-color: transparent;
                svg{
                    margin-right: 0;
                }
            }
			p{
				font-size: 14px;
    			width: 100%;
				color: #969696;
				margin-bottom: 0;
			}
		}
		.v-select__slot{
			font-weight: 300;
		}
		.primary-button{
			background-color: #FE8204 !important;
			color: #fff;
			font-weight: 500;
			font-size: 15px;
			padding: 15px 20px !important;
		}
		.secondary-button{
			color: #000;
			font-weight: 500;
			font-size: 15px;
			&:hover{
				background-color: transparent;
			}
		}
		.wrapper-btns{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            button{
                font-size: 18px;
            }
        }
	}
</style>